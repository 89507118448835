export function initCarousel(element, options = {}) {
  if (!element) {
    console.warn("Carousel element not found");
    return;
  }

  element.style.cssText = `
    position: relative;
    overflow: hidden;
  `;

  const container = element.querySelector(".carousel__container");

  const slides = Array.from(container.children);
  const prevArrow = element.querySelector(".carousel__arrow--prev");
  const nextArrow = element.querySelector(".carousel__arrow--next");
  const dots = Array.from(element.querySelectorAll(".dot"));

  const carouselOptions = {
    slidesPerView: options.slidesPerView || 1,
    spaceBetween: options.spaceBetween || 20,
    loop: true,
    breakpoints: options.breakpoints || null,
  };

  let currentIndex = 0;
  let slideWidth = 0;
  let touchStartX = 0;
  let touchEndX = 0;
  let isDragging = false;
  let startTranslate = 0;
  let currentTranslate = 0;
  let autoplayTimeoutId = null;
  let isHovered = false;

  const autoplayOptions = {
    enabled: options.autoplay?.enabled ?? true,
    delay: options.autoplay?.delay ?? 5000, // 5 seconds default
    pauseOnHover: options.autoplay?.pauseOnHover ?? true,
  };

  function calculateSlideWidth() {
    const totalGaps =
      (carouselOptions.slidesPerView - 1) * carouselOptions.spaceBetween;
    slideWidth =
      (container.offsetWidth - totalGaps) / carouselOptions.slidesPerView;
  }

  function setupSlides() {
    // Clear existing clones
    const clones = container.querySelectorAll(".clone");
    clones.forEach((clone) => clone.remove());

    // Recalculate slide width
    calculateSlideWidth();

    // Clone first and last slides
    const firstSlideClone = slides[0].cloneNode(true);
    const lastSlideClone = slides[slides.length - 1].cloneNode(true);

    // Mark clones for easy removal
    firstSlideClone.classList.add("clone");
    lastSlideClone.classList.add("clone");

    container.appendChild(firstSlideClone);
    container.insertBefore(lastSlideClone, slides[0]);

    const allSlides = Array.from(container.children);

    allSlides.forEach((slide) => {
      slide.style.cssText = `
        flex: 0 0 ${slideWidth}px;
        margin-right: ${carouselOptions.spaceBetween}px;
      `;
    });

    goToSlide(1, false);
  }

  function checkResponsive() {
    if (carouselOptions.breakpoints) {
      const windowWidth = window.innerWidth;
      Object.keys(carouselOptions.breakpoints)
        .sort((a, b) => b - a)
        .forEach((breakpoint) => {
          if (windowWidth <= breakpoint) {
            carouselOptions.slidesPerView =
              carouselOptions.breakpoints[breakpoint].slidesPerView;
          }
        });
    }
  }

  function updateDots(index) {
    if (dots.length) {
      dots.forEach((dot) => dot.classList.remove("active"));
      const normalizedIndex =
        index < 0 ? dots.length - 1 : index >= dots.length ? 0 : index;
      dots[normalizedIndex].classList.add("active");
    }
  }

  function goToSlide(index, smooth = true) {
    if (!smooth) {
      container.style.transition = "none";
    } else {
      container.style.transition = "transform 0.3s ease-in-out";
    }

    currentIndex = index;
    currentTranslate = -(index * (slideWidth + carouselOptions.spaceBetween));
    container.style.transform = `translateX(${currentTranslate}px)`;

    if (!smooth) {
      setTimeout(() => {
        container.style.transition = "transform 0.3s ease-in-out";
      }, 50);
    }

    updateDots(index - 1);
  }

  function next() {
    const maxIndex = slides.length + 1;

    if (currentIndex >= maxIndex) {
      goToSlide(1, false);
      setTimeout(() => goToSlide(2), 50);
    } else {
      goToSlide(currentIndex + 1);
    }
  }

  function prev() {
    if (currentIndex <= 0) {
      goToSlide(slides.length, false);
      setTimeout(() => goToSlide(slides.length - 1), 50);
    } else {
      goToSlide(currentIndex - 1);
    }
  }

  function startAutoplay() {
    if (!autoplayOptions.enabled || isHovered) return;

    if (autoplayTimeoutId) clearTimeout(autoplayTimeoutId);
    autoplayTimeoutId = setTimeout(() => {
      if (!isDragging && !isHovered) {
        next();
        startAutoplay();
      }
    }, autoplayOptions.delay);
  }

  function stopAutoplay() {
    if (autoplayTimeoutId) {
      clearTimeout(autoplayTimeoutId);
      autoplayTimeoutId = null;
    }
  }

  function setupInteractions() {
    // Touch events
    container.addEventListener("touchstart", handleDragStart, {
      passive: true,
    });
    container.addEventListener("touchmove", handleDragMove, { passive: false });
    container.addEventListener("touchend", handleDragEnd);

    // Mouse events for desktop drag
    container.addEventListener("mousedown", handleDragStart);
    container.addEventListener("mousemove", handleDragMove);
    container.addEventListener("mouseup", handleDragEnd);
    container.addEventListener("mouseleave", handleDragEnd);

    // Ensure hover events are set on the main carousel element
    element.addEventListener("mouseenter", () => {
      console.log("Mouse entered - stopping autoplay");
      isHovered = true;
      stopAutoplay();
    });

    element.addEventListener("mouseleave", () => {
      console.log("Mouse left - resuming autoplay");
      isHovered = false;
      startAutoplay();
    });
  }

  function handleDragStart(e) {
    isDragging = true;
    const point = e.touches ? e.touches[0] : e;
    touchStartX = point.clientX;
    startTranslate = currentTranslate;

    // Stop autoplay while dragging
    stopAutoplay();

    // Remove transition while dragging
    container.style.transition = "none";
  }

  function handleDragMove(e) {
    if (!isDragging) return;
    e.preventDefault();

    const point = e.touches ? e.touches[0] : e;
    touchEndX = point.clientX;
    const diff = touchEndX - touchStartX;

    // Add resistance at the ends
    const resistance = 0.25;
    const moveX = diff * resistance;

    currentTranslate = startTranslate + moveX;
    container.style.transform = `translateX(${currentTranslate}px)`;
  }

  function handleDragEnd() {
    if (!isDragging) return;
    isDragging = false;

    // Restore transition
    container.style.transition = "transform 0.3s ease-in-out";

    const movedBy = touchEndX - touchStartX;
    const threshold = slideWidth * 0.2; // 20% of slide width

    if (Math.abs(movedBy) >= threshold) {
      if (movedBy > 0) {
        prev();
      } else {
        next();
      }
    } else {
      // Snap back to current slide if threshold not met
      goToSlide(currentIndex);
    }

    // Restart autoplay after a delay
    if (autoplayOptions.enabled && !isHovered) {
      setTimeout(startAutoplay, autoplayOptions.delay);
    }
  }

  if (prevArrow && nextArrow) {
    prevArrow.addEventListener("click", () => {
      prev();
      stopAutoplay();
      if (autoplayOptions.enabled) {
        setTimeout(startAutoplay, autoplayOptions.delay);
      }
    });

    nextArrow.addEventListener("click", () => {
      next();
      stopAutoplay();
      if (autoplayOptions.enabled) {
        setTimeout(startAutoplay, autoplayOptions.delay);
      }
    });
  }

  if (dots.length) {
    dots.forEach((dot, index) => {
      dot.addEventListener("click", () => {
        goToSlide(index + 1);
        stopAutoplay();
        if (autoplayOptions.enabled) {
          setTimeout(startAutoplay, autoplayOptions.delay);
        }
      });
    });
  }

  window.addEventListener("resize", () => {
    checkResponsive();
    calculateSlideWidth();
    setupSlides();
    goToSlide(currentIndex);
  });

  calculateSlideWidth();
  setupSlides();
  checkResponsive();
  setupInteractions();
  startAutoplay();

  function destroy() {
    stopAutoplay();
    element.removeEventListener("mouseenter", stopAutoplay);
    element.removeEventListener("mouseleave", startAutoplay);
    // ... any other cleanup needed
  }

  return {
    next,
    prev,
    goToSlide,
    startAutoplay,
    stopAutoplay,
    destroy,
  };
}

export function initTeamCarousel(element, options = {}) {
  if (!element) {
    console.warn("Carousel element not found");
    return;
  }

  element.style.cssText = `
      position: relative;
      overflow: hidden;
    `;

  const container = element.querySelector(".team-carousel__container");

  const slides = Array.from(container.children);
  const prevArrow = element.querySelector(".carousel__arrow--prev");
  const nextArrow = element.querySelector(".carousel__arrow--next");
  const dots = Array.from(element.querySelectorAll(".dot"));

  const carouselOptions = {
    slidesPerView: options.slidesPerView || 3,
    spaceBetween: options.spaceBetween || 30,
    loop: true,
    breakpoints: options.breakpoints || {
      992: {
        // Desktop
        slidesPerView: 3,
        spaceBetween: 30,
      },
      768: {
        // Tablet
        slidesPerView: 2,
        spaceBetween: 20,
      },
      576: {
        // Mobile
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  };

  let currentIndex = 0;
  let slideWidth = 0;
  let touchStartX = 0;
  let touchEndX = 0;
  let isDragging = false;
  let startTranslate = 0;
  let currentTranslate = 0;
  let autoplayTimeoutId = null;
  let isHovered = false;

  const autoplayOptions = {
    enabled: options.autoplay?.enabled ?? true,
    delay: options.autoplay?.delay ?? 5000, // 5 seconds default
    pauseOnHover: options.autoplay?.pauseOnHover ?? true,
  };

  function calculateSlideWidth() {
    const containerWidth = container.offsetWidth;
    const totalSpaceBetween =
      (carouselOptions.slidesPerView - 1) * carouselOptions.spaceBetween;
    slideWidth =
      (containerWidth - totalSpaceBetween) / carouselOptions.slidesPerView;

    // Update slide widths and margins
    slides.forEach((slide) => {
      slide.style.width = `${slideWidth}px`;
      slide.style.marginRight = `${carouselOptions.spaceBetween}px`;
    });
  }

  function setupSlides() {
    // Clear existing clones
    const clones = container.querySelectorAll(".clone");
    clones.forEach((clone) => clone.remove());

    // Recalculate slide width
    calculateSlideWidth();

    // Instead of cloning just one slide at each end, clone enough slides to fill a view
    const slidesToClone = carouselOptions.slidesPerView;

    // Clone slides at the end
    for (let i = 0; i < slidesToClone; i++) {
      const clone = slides[i].cloneNode(true);
      clone.classList.add("clone");
      container.appendChild(clone);
    }

    // Clone slides at the beginning (in reverse order)
    for (
      let i = slides.length - 1;
      i >= Math.max(slides.length - slidesToClone, 0);
      i--
    ) {
      const clone = slides[i].cloneNode(true);
      clone.classList.add("clone");
      container.insertBefore(clone, container.firstChild);
    }

    const allSlides = Array.from(container.children);
    allSlides.forEach((slide) => {
      slide.style.cssText = `
        flex: 0 0 ${slideWidth}px;
        margin-right: ${carouselOptions.spaceBetween}px;
      `;
    });

    // Adjust initial position to show first real slide
    goToSlide(slidesToClone, false);

    // Clear existing dots first
    const dotsContainer = element.querySelector(".team-carousel__dots");
    if (dotsContainer) {
      dotsContainer.innerHTML = "";
      // Create correct number of dots based on actual slides
      slides.forEach((_, index) => {
        const dot = document.createElement("button");
        dot.className = "dot";
        dot.setAttribute("aria-label", `Go to slide ${index + 1}`);

        // Add click event listener when creating each dot
        dot.addEventListener("click", () => {
          console.log("Dot clicked:", index);
          const targetIndex = index + carouselOptions.slidesPerView;
          console.log("Target index:", targetIndex);

          container.style.transition = "transform 0.3s ease-in-out";
          goToSlide(targetIndex);

          stopAutoplay();
          if (autoplayOptions.enabled && !isHovered) {
            setTimeout(startAutoplay, autoplayOptions.delay);
          }
        });

        dotsContainer.appendChild(dot);
      });

      // Reassign dots array after creating new ones
      dots.length = 0;
      dots.push(...element.querySelectorAll(".dot"));
    }
  }

  function checkResponsive() {
    const windowWidth = window.innerWidth;

    if (windowWidth <= 576) {
      carouselOptions.slidesPerView = 1;
      carouselOptions.spaceBetween = 10;
    } else if (windowWidth <= 992) {
      carouselOptions.slidesPerView = 2;
      carouselOptions.spaceBetween = 20;
    } else {
      carouselOptions.slidesPerView = 3;
      carouselOptions.spaceBetween = 30;
    }

    calculateSlideWidth();
    setupSlides();
    goToSlide(currentIndex, false);
  }

  function updateDots(index) {
    if (dots.length) {
      dots.forEach((dot) => dot.classList.remove("active"));
      const normalizedIndex =
        (((index - carouselOptions.slidesPerView) % slides.length) +
          slides.length) %
        slides.length;
      dots[normalizedIndex].classList.add("active");
    }
  }

  function goToSlide(index, smooth = true) {
    console.log("Going to slide:", index);

    if (!smooth) {
      container.style.transition = "none";
    } else {
      container.style.transition = "transform 0.3s ease-in-out";
    }

    currentIndex = index;
    currentTranslate = -(index * (slideWidth + carouselOptions.spaceBetween));
    container.style.transform = `translateX(${currentTranslate}px)`;

    if (!smooth) {
      setTimeout(() => {
        container.style.transition = "transform 0.3s ease-in-out";
      }, 50);
    }

    updateDots(index);
  }

  function next() {
    const maxIndex = slides.length + carouselOptions.slidesPerView;

    if (currentIndex >= maxIndex) {
      goToSlide(carouselOptions.slidesPerView, false);
      setTimeout(() => goToSlide(carouselOptions.slidesPerView + 1), 50);
    } else {
      goToSlide(currentIndex + 1);
    }
  }

  function prev() {
    if (currentIndex <= carouselOptions.slidesPerView - 1) {
      goToSlide(slides.length + carouselOptions.slidesPerView - 1, false);
      setTimeout(
        () => goToSlide(slides.length + carouselOptions.slidesPerView - 2),
        50
      );
    } else {
      goToSlide(currentIndex - 1);
    }
  }

  function startAutoplay() {
    if (!autoplayOptions.enabled || isHovered) return;

    if (autoplayTimeoutId) clearTimeout(autoplayTimeoutId);
    autoplayTimeoutId = setTimeout(() => {
      if (!isDragging && !isHovered) {
        next();
        startAutoplay();
      }
    }, autoplayOptions.delay);
  }

  function stopAutoplay() {
    if (autoplayTimeoutId) {
      clearTimeout(autoplayTimeoutId);
      autoplayTimeoutId = null;
    }
  }

  function setupInteractions() {
    // Touch events
    container.addEventListener("touchstart", handleDragStart, {
      passive: true,
    });
    container.addEventListener("touchmove", handleDragMove, { passive: false });
    container.addEventListener("touchend", handleDragEnd);

    // Mouse events for desktop drag
    container.addEventListener("mousedown", handleDragStart);
    container.addEventListener("mousemove", handleDragMove);
    container.addEventListener("mouseup", handleDragEnd);
    container.addEventListener("mouseleave", handleDragEnd);

    // Ensure hover events are set on the main carousel element
    element.addEventListener("mouseenter", () => {
      console.log("Mouse entered - stopping autoplay");
      isHovered = true;
      stopAutoplay();
    });

    element.addEventListener("mouseleave", () => {
      console.log("Mouse left - resuming autoplay");
      isHovered = false;
      startAutoplay();
    });
  }

  function handleDragStart(e) {
    isDragging = true;
    const point = e.touches ? e.touches[0] : e;
    touchStartX = point.clientX;
    startTranslate = currentTranslate;

    // Stop autoplay while dragging
    stopAutoplay();

    // Remove transition while dragging
    container.style.transition = "none";
  }

  function handleDragMove(e) {
    if (!isDragging) return;
    e.preventDefault();

    const point = e.touches ? e.touches[0] : e;
    touchEndX = point.clientX;
    const diff = touchEndX - touchStartX;

    // Add resistance at the ends
    const resistance = 0.25;
    const moveX = diff * resistance;

    currentTranslate = startTranslate + moveX;
    container.style.transform = `translateX(${currentTranslate}px)`;
  }

  function handleDragEnd() {
    if (!isDragging) return;
    isDragging = false;

    // Restore transition
    container.style.transition = "transform 0.3s ease-in-out";

    const movedBy = touchEndX - touchStartX;
    const threshold = slideWidth * 0.2; // 20% of slide width

    if (Math.abs(movedBy) >= threshold) {
      if (movedBy > 0) {
        prev();
      } else {
        next();
      }
    } else {
      // Snap back to current slide if threshold not met
      goToSlide(currentIndex);
    }

    // Restart autoplay after a delay
    if (autoplayOptions.enabled && !isHovered) {
      setTimeout(startAutoplay, autoplayOptions.delay);
    }
  }

  if (prevArrow && nextArrow) {
    prevArrow.addEventListener("click", () => {
      prev();
      stopAutoplay();
      if (autoplayOptions.enabled) {
        setTimeout(startAutoplay, autoplayOptions.delay);
      }
    });

    nextArrow.addEventListener("click", () => {
      next();
      stopAutoplay();
      if (autoplayOptions.enabled) {
        setTimeout(startAutoplay, autoplayOptions.delay);
      }
    });
  }

  window.addEventListener("resize", () => {
    checkResponsive();
    calculateSlideWidth();
    setupSlides();
    goToSlide(currentIndex);
  });

  calculateSlideWidth();
  setupSlides();
  checkResponsive();
  setupInteractions();
  updateDots(carouselOptions.slidesPerView);
  startAutoplay();

  function destroy() {
    stopAutoplay();
    element.removeEventListener("mouseenter", stopAutoplay);
    element.removeEventListener("mouseleave", startAutoplay);
    // ... any other cleanup needed
  }

  return {
    next,
    prev,
    goToSlide,
    startAutoplay,
    stopAutoplay,
    destroy,
  };
}

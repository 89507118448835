import { initTabs } from "./modules/tabs";
import { initModal } from "./modules/modal";
import { initNavbar } from "./modules/navbar";
import { initLanguageDropdown } from "./modules/language-dropdown";
import { initLazyLoad } from "./modules/lazy-load";
import { initCarousel } from "./modules/carousel";
import { initTeamCarousel } from "./modules/team-carousel";
import { handleScrollAnimations } from "./modules/scroll-animations";
import { MasonryGallery } from "./modules/mansory-gallery";
import { initContactForm } from "./modules/contact-form";
import { initFaq } from "./modules/faq";

document.addEventListener("DOMContentLoaded", () => {
  initTabs();
  initModal();
  initNavbar();
  initLanguageDropdown();
  initLazyLoad();
  handleScrollAnimations();
  initContactForm();
  initFaq();

  const imageGallery = document.querySelector(".masonry-gallery");
  if (imageGallery) {
    // Initialize gallery
    new MasonryGallery();
  }

  // Initialize carousel with options
  const carousel = document.querySelector(".carousel");
  if (carousel) {
    initCarousel(carousel);
  }

  const teamCarousel = document.querySelector(".team-carousel");
  if (teamCarousel) {
    initTeamCarousel(teamCarousel, {
      slidesPerView: 3,
      spaceBetween: 30,
      autoplay: {
        enabled: true,
        delay: 3000,
      },
      breakpoints: {
        992: {
          // Desktop
          slidesPerView: 3,
          spaceBetween: 30,
        },
        768: {
          // Tablet
          slidesPerView: 2,
          spaceBetween: 20,
        },
        576: {
          // Mobile
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
    });
  }
});

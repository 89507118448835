export function initNavbar() {
  const navbar = document.querySelector(".navbar");
  const topbar = document.querySelector(".topbar");
  const hamburger = document.querySelector(".navbar__hamburger");
  const mobileMenu = document.querySelector(".navbar__menu");

  //STICKY MENU ON SCROLL
  let lastScrollTop = 0;
  window.addEventListener("scroll", function () {
    let scrollTop = window.scrollY || document.documentElement.scrollTop;

    if (scrollTop > lastScrollTop) {
      // Scrolling down
      navbar.classList.add("scrolled");
      topbar.style.transform = "translateY(-100%)";
    } else {
      // Scrolling up
      navbar.classList.remove("scrolled");
      topbar.style.transform = "translateY(0)";
    }
  });

  //RESPONSIVE MENU - MOBILE MENU
  // Toggle menu on hamburger click
  hamburger?.addEventListener("click", () => {
    hamburger.classList.toggle("active");
    mobileMenu?.classList.toggle("active");
  });

  // Handle submenu toggles for mobile
  const menuItems = document.querySelectorAll(".navbar__item");
  menuItems.forEach((item) => {
    const submenu = item.querySelector(".navbar__submenu");
    if (submenu) {
      const button = item.querySelector("button.navbar__link");
      button?.addEventListener("click", (e) => {
        // Only toggle on mobile
        if (window.innerWidth <= 900) {
          e.preventDefault();
          submenu.classList.toggle("active");
        }
      });
    }
  });

  // Close menu when clicking outside
  document.addEventListener("click", (e) => {
    if (
      !e.target.closest(".navbar__menu") &&
      !e.target.closest(".navbar__hamburger") &&
      mobileMenu?.classList.contains("active")
    ) {
      mobileMenu.classList.remove("active");
      hamburger?.classList.remove("active");
    }
  });
}

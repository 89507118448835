export function initLazyLoad() {
  // Check if browser supports IntersectionObserver
  if ("IntersectionObserver" in window) {
    // Handle regular images with src attribute
    const lazyImages = document.querySelectorAll('img[loading="lazy"]');
    const imageObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target;
            if (img.dataset.src) {
              img.src = img.dataset.src;
              img.removeAttribute("data-src");
            }
            observer.unobserve(img);
          }
        });
      },
      {
        rootMargin: "50px 0px", // Start loading 50px before image enters viewport
        threshold: 0.01,
      }
    );

    lazyImages.forEach((img) => imageObserver.observe(img));

    // Handle background images
    const lazyBackgrounds = document.querySelectorAll("[data-background]");
    const bgObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const element = entry.target;
            if (element.dataset.background) {
              element.style.backgroundImage = `url(${element.dataset.background})`;
              element.removeAttribute("data-background");
            }
            observer.unobserve(element);
          }
        });
      },
      {
        rootMargin: "50px 0px",
        threshold: 0.01,
      }
    );

    lazyBackgrounds.forEach((element) => bgObserver.observe(element));
  } else {
    // Fallback for browsers that don't support IntersectionObserver
    const loadImages = () => {
      const lazyImages = document.querySelectorAll('img[loading="lazy"]');
      const lazyBackgrounds = document.querySelectorAll("[data-background]");

      // Load regular images
      lazyImages.forEach((img) => {
        if (img.dataset.src) {
          img.src = img.dataset.src;
          img.removeAttribute("data-src");
        }
      });

      // Load background images
      lazyBackgrounds.forEach((element) => {
        if (element.dataset.background) {
          element.style.backgroundImage = `url(${element.dataset.background})`;
          element.removeAttribute("data-background");
        }
      });
    };

    // Load all images after a short delay
    setTimeout(loadImages, 100);
  }
}

export function initLanguageDropdown() {
  const languageBtn = document.querySelector(".language-btn");
  const languageDropdown = document.querySelector(".language-dropdown");

  if (languageBtn && languageDropdown) {
    // Initially hide dropdown
    languageDropdown.style.display = "none";

    languageBtn.addEventListener("click", (e) => {
      e.stopPropagation();

      // Open or close the language dropdown
      languageDropdown.style.display =
        languageDropdown.style.display === "none" ? "block" : "none";
    });

    // Close dropdown when clicking outside
    document.addEventListener("click", (e) => {
      if (
        !languageBtn.contains(e.target) &&
        !languageDropdown.contains(e.target)
      ) {
        languageDropdown.style.display = "none";
      }
    });
  }
}

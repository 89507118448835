export function initContactForm() {
  const form = document.getElementById("contactForm");
  const formMessage = document.getElementById("formMessage");

  if (form) {
    form.addEventListener("submit", handleFormSubmit);
  }
}

function handleFormSubmit(e) {
  e.preventDefault();

  const form = e.currentTarget;
  const formMessage = document.getElementById("formMessage");

  // Clear previous messages
  formMessage.innerHTML = "";
  formMessage.className = "contact-form__message";

  // Collect form data
  const formData = new FormData(form);

  // Ensure CSRF token is included
  const csrfToken = form.querySelector('input[name="csrf_token"]').value;
  formData.append("csrf_token", csrfToken);

  // Show loading state
  const submitButton = form.querySelector('button[type="submit"]');
  const originalButtonText = submitButton.innerHTML;
  submitButton.innerHTML = "<span>Šaljem...</span>";
  submitButton.disabled = true;

  // Send the form
  fetch("process-form.php", {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        formMessage.className =
          "contact-form__message contact-form__message--success";
        formMessage.textContent = data.message;
        form.reset();
      } else {
        formMessage.className =
          "contact-form__message contact-form__message--error";
        if (data.errors && data.errors.length) {
          const ul = document.createElement("ul");
          data.errors.forEach((error) => {
            const li = document.createElement("li");
            li.textContent = error;
            ul.appendChild(li);
          });
          formMessage.appendChild(ul);
        } else {
          formMessage.textContent = data.message;
        }
      }
    })
    .catch((error) => {
      formMessage.className =
        "contact-form__message contact-form__message--error";
      formMessage.textContent = "Došlo je do greške. Molimo pokušajte ponovno.";
    })
    .finally(() => {
      submitButton.innerHTML = originalButtonText;
      submitButton.disabled = false;
    });
}

class MasonryGallery {
  constructor() {
    this.gallery = document.querySelector(".masonry-gallery");
    this.modal = null;
    this.currentImageIndex = 0;
    this.images = [];
    this.touchStartX = 0;
    this.touchEndX = 0;

    this.init();
  }

  init() {
    if (!this.gallery) return;

    this.createModal();

    // Get all images and handle lazy loading
    this.images = Array.from(this.gallery.querySelectorAll("img"));
    this.images.forEach((img) => {
      // Set initial src from data-src
      if (img.dataset.src && !img.src) {
        img.src = img.dataset.src;
      }

      // Add loading class
      img.classList.add("loading");

      // Remove loading class when image loads
      img.onload = () => {
        img.classList.remove("loading");
      };
    });

    this.initializeEventListeners();
  }

  createModal() {
    this.modal = document.createElement("div");
    this.modal.className = "masonry-modal";
    this.modal.innerHTML = `
            <div class="modal-content">
                <button class="close-btn">&times;</button>
               <button class="nav-btn prev-btn">
  <svg viewBox="0 0 24 24">
    <path d="M15 18l-6-6 6-6"/>
                    </svg>
                </button>
               <button class="nav-btn next-btn">
  <svg viewBox="0 0 24 24">
    <path d="M9 18l6-6-6-6"/>
  </svg>
</button>
                <img src="" alt="Modal Image">
            </div>
        `;
    document.body.appendChild(this.modal);
  }

  initializeEventListeners() {
    this.gallery.addEventListener("click", (e) => {
      if (e.target.tagName === "IMG") {
        this.openModal(Array.from(this.images).indexOf(e.target));
      }
    });

    this.modal
      .querySelector(".close-btn")
      .addEventListener("click", () => this.closeModal());
    this.modal
      .querySelector(".prev-btn")
      .addEventListener("click", () =>
        this.showImage(this.currentImageIndex - 1)
      );
    this.modal
      .querySelector(".next-btn")
      .addEventListener("click", () =>
        this.showImage(this.currentImageIndex + 1)
      );

    this.modal.addEventListener("touchstart", (e) => {
      this.touchStartX = e.changedTouches[0].screenX;
    });

    this.modal.addEventListener("touchend", (e) => {
      this.touchEndX = e.changedTouches[0].screenX;
      this.handleSwipe();
    });

    document.addEventListener("keydown", (e) => {
      if (!this.modal.classList.contains("active")) return;

      if (e.key === "Escape") this.closeModal();
      if (e.key === "ArrowLeft") this.showImage(this.currentImageIndex - 1);
      if (e.key === "ArrowRight") this.showImage(this.currentImageIndex + 1);
    });
  }

  handleSwipe() {
    const swipeDistance = this.touchEndX - this.touchStartX;
    const minSwipeDistance = 50;

    if (Math.abs(swipeDistance) < minSwipeDistance) return;

    if (swipeDistance > 0) {
      this.showImage(this.currentImageIndex - 1);
    } else {
      this.showImage(this.currentImageIndex + 1);
    }
  }

  openModal(index) {
    this.currentImageIndex = index;
    this.modal.classList.add("active");
    this.showImage(index);
  }

  closeModal() {
    this.modal.classList.remove("active");
  }

  showImage(index) {
    if (index < 0) index = this.images.length - 1;
    if (index >= this.images.length) index = 0;

    this.currentImageIndex = index;
    const modalImg = this.modal.querySelector("img");
    const targetImage = this.images[index];

    // Use the data-src if available, otherwise use src
    modalImg.src = targetImage.dataset.src || targetImage.src;
  }
}

export { MasonryGallery };
